<template>
  <div class="app-container">
    <div v-if="dialogVisible" class="select-dialog">
      <div class="dialog-box">
        <div v-show="executing" class="title">支付结果查询中<span class="dotting">...</span></div>
        <div v-show="!executing" class="text">请确认您已成功支付，并核对第三方支付账单</div>
        <div class="btn-group">
          <div v-show="successBtnEnabled" class="success-btn" @click="handleCheckOrder">支付完成</div>
          <router-link :to="{name: 'StreamLanding', params: { id:$route.query.goodsId }}" tag="div" class="error-btn">返回商品</router-link>
        </div>
      </div>
    </div>
    <template v-if="info.wx">
      <div class="success-header" />
      <div class="header-tips">添加“助教微信号”即可学习课程</div>
      <div class="copy-box">
        <span class="wx-text">{{ info.wx }}</span>
        <div class="copy-btn" :data-clipboard-text="info.wx">一键复制</div>
      </div>
      <div class="step-text" />
      <div class="step-img">
        <div class="title" />
        <div class="step-1">
          <span class="arrow-1" />
          <strong class="step-wx-1">{{ info.wx }}</strong>
        </div>
        <div class="step-2">
          <span class="arrow-2" />
        </div>
        <div class="step-3">
          <span class="arrow-3" />
        </div>
        <div class="step-4">
          <span class="arrow-4" />
          <strong class="step-wx-4-1">{{ info.wx }}</strong>
          <strong class="step-wx-4-2">{{ info.wx }}</strong>
        </div>
        <div class="step-5">
          <span class="arrow-5" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import {Landing} from '@/network'
import {Toast} from 'vant'
import Clipboard from 'clipboard'

export default {
  data() {
    return {
      info: {},
      dialogVisible: true,
      executing: false,
      successBtnEnabled: false
    }
  },
  created() {
    window.sessionStorage.removeItem('orderInfo')
    this.checkOrder(30)
  },
  methods: {
    async handleCheckOrder() {
      this.successBtnEnabled = false
      await this.checkOrder(1)
      this.successBtnEnabled = false
    },
    async checkOrder(count = 3) {
      this.executing = true
      const {data} = await Landing.getSmallOrder({
        outTradeNo: this.$route.params.outTradeNo
      })
      count--
      if ((!data || !data.salesmanId) && !count) {
        this.dialogVisible = true
        this.executing = false
        this.successBtnEnabled = true
        return
      }
      if (!data || !data.salesmanId) return setTimeout(async() => await this.checkOrder(count), 2000)
      this.dialogVisible = false
      this.handleGetSalesmanInfo(data.salesmanId)
      window.xmlyAdLog.track(6, {key: '2ac9508822e3465ea312b286268d433a'})
    },
    async handleGetSalesmanInfo(id) {
      const {data} = await Landing.getSalesmanInfo({id})
      this.info = data
      this.handleSetReadFinish()
      this.handleClipboard()
    },
    handleClipboard() {
      const clipboard = new Clipboard('.copy-btn')
      clipboard.on('success', () => {
        Toast('复制成功')
        setTimeout(() => {
          window.location.href = 'weixin://'
        }, 2000)
      })
    },
    handleSetReadFinish() {
      const outTradeNo = this.$route.params.outTradeNo
      setTimeout(() => {
        Landing.setReadFinish({outTradeNo})
      }, 500)
    }
  }
}
</script>
<style lang="scss" scoped>
@keyframes copy-btn-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes arrow-right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes arrow-left {
  0% {
    transform: rotateZ(180deg) translateX(0);
  }
  50% {
    transform: rotateZ(180deg) translateX(-5px);
  }
  100% {
    transform: rotateZ(180deg) translateX(0);
  }
}
.app-container {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: relative;
  .success-header {
    width: 100%;
    height: 198px;
    background-image: url('~@/assets/images/success-header-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 19px;
  }
  .header-tips {
    width: 100%;
    height: 17px;
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0d0d0d;
    line-height: 23px;
    text-align: center;
    margin-bottom: 15px;
  }
  .copy-box {
    width: 345px;
    height: 56px;
    background: #fdeff1;
    border-radius: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 11px 0 25px;
    margin: 0 auto 19px;
    .wx-text {
      width: 200px;
      height: 18px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #e73c2f;
      line-height: 18px;
    }
    .copy-btn {
      width: 120px;
      height: 36px;
      background: #e73c2f;
      box-shadow: 0px 3px 0px 0px rgba(235, 0, 0, 0.34);
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      line-height: 18px;
      animation: 0.4s copy-btn-scale infinite linear;
    }
  }
  .step-text {
    width: 357px;
    height: 254px;
    background-image: url('~@/assets/images/step-text-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0 auto 19px;
  }
  .step-img {
    width: 342px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(242, 88, 69, 0.26);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 13px 10px 30px;
    margin: 0 auto 56px;
    .title {
      width: 204px;
      height: 51px;
      margin-bottom: 10px;
      background-image: url('~@/assets/images/step-img-title-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .step-1 {
      width: 100%;
      height: 157px;
      background-image: url('~@/assets/images/step-img-1.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: relative;
    }
    .step-2 {
      @extend .step-1;
      background-image: url('~@/assets/images/step-img-2.png');
      height: 152px;
    }
    .step-3 {
      @extend .step-1;
      background-image: url('~@/assets/images/step-img-3.png');
      height: 245px;
    }
    .step-4 {
      @extend .step-1;
      background-image: url('~@/assets/images/step-img-4.png');
      height: 152px;
    }
    .step-5 {
      @extend .step-1;
      background-image: url('~@/assets/images/step-img-5.png');
      height: 232px;
    }
    .arrow-1 {
      width: 38px;
      height: 20px;
      background-image: url('~@/assets/images/step-arrow.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      left: 150px;
      top: 95px;
      animation: 0.6s arrow-right infinite linear;
    }
    .arrow-2 {
      @extend .arrow-1;
      left: 210px;
      top: 40px;
    }
    .arrow-3 {
      @extend .arrow-1;
      left: 80px;
      top: 35px;
      animation: 0.6s arrow-left infinite linear;
    }
    .arrow-4 {
      @extend .arrow-3;
      left: 270px;
      top: 80px;
    }
    .arrow-5 {
      @extend .arrow-1;
      left: 70px;
      top: 140px;
    }
    .step-wx-1 {
      width: 160px;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-weight: normal;
      position: absolute;
      left: 40px;
      top: 94px;
    }
    .step-wx-4-1 {
      @extend .step-wx-1;
      width: 200px;
      left: 40px;
      top: 33px;
    }
    .step-wx-4-2 {
      @extend .step-wx-1;
      width: 160px;
      left: 155px;
      top: 82px;
    }
  }
}
.select-dialog {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.79);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .dialog-box {
    width: 340px;
    height: auto;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #979797;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 28px 0;
    margin-top: 120px;
    .title {
      width: auto;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff4e08;
      line-height: 28px;
      margin-bottom: 14px;
    }
    .text {
      width: auto;
      line-height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #949397;
      text-align: left;
      margin-bottom: 20px;
    }
    .btn-group {
      display: flex;
      justify-content: center;
      align-items: center;
      .success-btn {
        width: 105px;
        height: 32px;
        background: linear-gradient(119deg, #ff4100 0%, #ff580e 100%);
        border-radius: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin-right: 15px;
      }
      .error-btn {
        width: 105px;
        height: 32px;
        border-radius: 17px;
        border: 1px solid #979797;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #979797;
        line-height: 18px;
      }
    }
  }
}
@supports (display:none) {
  .dotting {
    display: inline-block;
    width: 3ch;
    text-indent: -1ch;
    vertical-align: bottom;
    overflow: hidden;
    animation: dot 3s infinite step-start both;
    font-family: Consolas, Monaco, monospace;
  }
}

@keyframes dot {
  33% { text-indent: 0; }
  66% { text-indent: -2ch; }
}
</style>
